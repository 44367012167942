var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cabecalho-pagina',{attrs:{"titulo":_vm.$t('modulos.duvida_solicitacao_participante.titulos.listagem'),"total-registros":_vm.tabela.quantidadeItens,"titulo-botao-novo":_vm.$t('modulos.duvida_solicitacao_participante.titulos.novo'),"sem-botao-ok":!_vm.buscarPermissao('DuvidaSolicitacaoParticipante', 'Inserir'),"sem-filtro":!_vm.buscarPermissao('DuvidaSolicitacaoParticipante', 'Listar'),"date-only":""},on:{"abrirNovo":_vm.abrirNovo,"filtrar":_vm.filtrar},scopedSlots:_vm._u([{key:"filtro",fn:function({ filtro }){return [_c('filtro',{attrs:{"filtro":filtro}})]}}])}),(
      _vm.podeExcluir &&
      _vm.buscarPermissao('DuvidaSolicitacaoParticipante', 'Deletar')
    )?_c('botao-padrao',{staticClass:"my-2",attrs:{"outlined":"","color":"danger"},on:{"click":_vm.excluirRegistros}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.excluir'))+" ")]):_vm._e(),_c('tabela-padrao-prime-vue',{staticClass:"mt-2",attrs:{"filtros-com-colunas":"","colunas":_vm.tabela.colunas,"dados":_vm.tabela.dados,"mostrar-acoes":"","selecionar-colunas":"","sem-selecionar-todos":"","quantidade-itens":_vm.tabela.quantidadeItens,"por-pagina":_vm.tabela.porPagina,"quantidade-paginas":_vm.tabela.quantidadePaginas,"pagina":_vm.tabela.paginaAtual,"mostrar-seletor":_vm.buscarPermissao('DuvidaSolicitacaoParticipante', 'Deletar')},on:{"paginar":_vm.listarRegistros,"filtrarGlobal":_vm.filtrarGlobal,"filtroGlobal":_vm.filtroGlobal,"nova-ordenacao":_vm.ordenacaoColuna},scopedSlots:_vm._u([{key:"acoes",fn:function({ slotProps }){return [_c('dropdown-padrao',{attrs:{"text":"","color":"secondary"},scopedSlots:_vm._u([{key:"botao",fn:function(){return [_c('v-icon',[_vm._v(" $dotsVertical ")])]},proxy:true}],null,true)},[_c('v-list-item-group',{staticStyle:{"overflow-y":"auto"}},[(_vm.buscarPermissao('DuvidaSolicitacaoParticipante', 'Editar'))?_c('v-list-item',{staticClass:"d-flex min-height-drop-item",on:{"click":function($event){return _vm.abrirEditar(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.editar'))+" ")]):_vm._e(),_c('v-divider'),(
              _vm.buscarPermissao(
                'DuvidaSolicitacaoParticipante',
                'AlterarSituacao'
              )
            )?_c('dropdown-padrao',{attrs:{"submenu":"","color":"secondary"},scopedSlots:_vm._u([{key:"botao",fn:function(){return [_c('v-list-item-group',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.alterar_situacao'))+" ")])]},proxy:true}],null,true)},[_c('v-list-item-group',{staticStyle:{"overflow-y":"auto"}},[_c('v-list-item',{staticClass:"d-flex",on:{"click":function($event){return _vm.alterarStatus(slotProps.data, 'EmAnalise')}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.em_analise'))+" ")]),_c('v-divider'),_c('v-list-item',{staticClass:"d-flex",on:{"click":function($event){return _vm.alterarStatus(slotProps.data, 'EmProcessoResolucao')}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.em_processo_resolucao'))+" ")]),_c('v-divider'),_c('v-list-item',{staticClass:"d-flex",on:{"click":function($event){return _vm.alterarStatus(slotProps.data, 'Resolvido')}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.resolvido'))+" ")]),_c('v-divider'),_c('v-list-item',{staticClass:"d-flex",on:{"click":function($event){return _vm.alterarStatus(slotProps.data, 'Concluido')}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.concluido'))+" ")])],1)],1):_vm._e()],1)],1)]}},{key:"status",fn:function({ slotProps }){return [_c('div',{staticClass:"d-flex align-center",style:(`color:${_vm.statusCores[slotProps.data.status]}`)},[_vm._v(" "+_vm._s(_vm.formatarStatus(slotProps.data.status))+" ")])]}}]),model:{value:(_vm.tabela.selecionados),callback:function ($$v) {_vm.$set(_vm.tabela, "selecionados", $$v)},expression:"tabela.selecionados"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }