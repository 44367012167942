<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.duvida_solicitacao_participante.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="
        $t('modulos.duvida_solicitacao_participante.titulos.novo')
      "
      :sem-botao-ok="
        !buscarPermissao('DuvidaSolicitacaoParticipante', 'Inserir')
      "
      :sem-filtro="!buscarPermissao('DuvidaSolicitacaoParticipante', 'Listar')"
      date-only
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="
        podeExcluir &&
        buscarPermissao('DuvidaSolicitacaoParticipante', 'Deletar')
      "
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :mostrar-seletor="
        buscarPermissao('DuvidaSolicitacaoParticipante', 'Deletar')
      "
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <v-list-item-group style="overflow-y: auto">
            <v-list-item
              v-if="buscarPermissao('DuvidaSolicitacaoParticipante', 'Editar')"
              class="d-flex min-height-drop-item"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </v-list-item>
            <v-divider />
            <dropdown-padrao
              v-if="
                buscarPermissao(
                  'DuvidaSolicitacaoParticipante',
                  'AlterarSituacao'
                )
              "
              submenu
              color="secondary"
            >
              <template #botao>
                <v-list-item-group style="font-size: 14px">
                  {{ $t('geral.botoes.alterar_situacao') }}
                </v-list-item-group>
              </template>
              <v-list-item-group style="overflow-y: auto">
                <v-list-item
                  class="d-flex"
                  @click="alterarStatus(slotProps.data, 'EmAnalise')"
                >
                  {{ $t('geral.botoes.em_analise') }}
                </v-list-item>
                <v-divider />
                <v-list-item
                  class="d-flex"
                  @click="alterarStatus(slotProps.data, 'EmProcessoResolucao')"
                >
                  {{ $t('geral.botoes.em_processo_resolucao') }}
                </v-list-item>
                <v-divider />
                <v-list-item
                  class="d-flex"
                  @click="alterarStatus(slotProps.data, 'Resolvido')"
                >
                  {{ $t('geral.botoes.resolvido') }}
                </v-list-item>
                <v-divider />
                <v-list-item
                  class="d-flex"
                  @click="alterarStatus(slotProps.data, 'Concluido')"
                >
                  {{ $t('geral.botoes.concluido') }}
                </v-list-item>
              </v-list-item-group>
            </dropdown-padrao>
          </v-list-item-group>
        </dropdown-padrao>
      </template>
      <template v-slot:status="{ slotProps }">
        <div
          :style="`color:${statusCores[slotProps.data.status]}`"
          class="d-flex align-center"
        >
          {{ formatarStatus(slotProps.data.status) }}
        </div>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import DuvidaSolicitacaoParticipanteService from '@common/services/cadastros/DuvidaSolicitacaoParticipanteService';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import Filtro from './filtro.vue';
import helpers from '@common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'dataAbertura',
            text: this.$t(
              'modulos.duvida_solicitacao_participante.tabela.data_abertura'
            ),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'categoria',
            text: this.$t(
              'modulos.duvida_solicitacao_participante.tabela.categoria'
            ),
            sortable: true,
            formatter: (v) => {
              if (v)
                return this.enumCategoria.find((el) => el.value === v)?.text;
            },
          },
          {
            value: 'subCategoria',
            text: this.$t(
              'modulos.duvida_solicitacao_participante.tabela.sub_categoria'
            ),
            sortable: false,
            formatter: (v) => {
              if (v) return this.enumAssunto.find((el) => el.value === v)?.text;
            },
          },
          {
            value: 'comentario',
            text: this.$t(
              'modulos.duvida_solicitacao_participante.tabela.comentario'
            ),
            sortable: true,
            formatter: (v) => {
              if (v) {
                const maxLength = 20;
                if (v.length > maxLength) {
                  return v.slice(0, maxLength) + '...';
                } else {
                  return v;
                }
              }
            },
          },
          {
            value: 'dataConclusao',
            text: this.$t(
              'modulos.duvida_solicitacao_participante.tabela.data_conclusao'
            ),
            sortable: true,
            formatter: (v) => {
              if (v !== null && v !== undefined) {
                return helpers.formatarDataBr(v, false);
              } else {
                return 'N/A';
              }
            },
          },
          {
            value: 'status',
            text: this.$t(
              'modulos.duvida_solicitacao_participante.tabela.situacao'
            ),
            sortable: true,
            formatter: (v) => {
              if (v) return this.enumStatus.find((el) => el.value === v)?.text;
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
        colorStatus: null,
      },
      filtroAtualizado: {},
      filtroAplicado: { filter: '' },
      enumStatus: [],
      enumCategoria: [],
      enumAssunto: [],
      statusCores: {
        EmAberto: '#F79009',
        EmAnalise: '#667085',
        EmProcessoResolucao: '#B42318',
        Resolvido: '#1849A9',
        Concluido: '#027A48',
      },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  async mounted() {
    await this.buscarEnumStatus();
    await this.buscarEnumCategoria();
    await this.buscarEnumAssunto();
    await this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.duvida_solicitacao_participante.titulos.listagem')
    );
  },
  created() {
    helpers.redirecionarSemPermissao(
      this,
      'DuvidaSolicitacaoParticipante',
      'Listar'
    );
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAtualizado = filtro;
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      DuvidaSolicitacaoParticipanteService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.tabela.selecionados.forEach((item) => {
          DuvidaSolicitacaoParticipanteService.excluir(item.id)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(
                this.$t(
                  `modulos.duvida_solicitacao_participante.exclusao_sucesso`
                )
              );
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.tabela.selecionados = [];
            });
        });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'duvida-solicitacao-participante-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'duvida-solicitacao-participante-editar',
        params: { id: item.id },
      });
    },
    buscarEnumStatus: function () {
      EnumeradorService.buscar('EnumStatusDuvidaSolicitacaoParticipante').then(
        (res) => {
          this.enumStatus = res;
        }
      );
    },
    buscarEnumCategoria: function () {
      EnumeradorService.buscar(
        'EnumCatetegoriaDuvidaSolicitacaoParticipante'
      ).then((res) => {
        this.enumCategoria = res;
      });
    },
    buscarEnumAssunto: function () {
      EnumeradorService.buscar(
        'EnumSubCategoriaDuvidaSolicitacaoParticipanteInstrumento'
      ).then((res) => {
        this.enumAssunto.push(...res);
      });
      EnumeradorService.buscar(
        'EnumSubCategoriaDuvidaSolicitacaoParticipanteCertificado'
      ).then((res) => {
        this.enumAssunto.push(...res);
      });
      EnumeradorService.buscar(
        'EnumSubCategoriaDuvidaSolicitacaoParticipanteOrdemServico'
      ).then((res) => {
        this.enumAssunto.push(...res);
      });
      EnumeradorService.buscar(
        'EnumSubCategoriaDuvidaSolicitacaoParticipanteServicoCampo'
      ).then((res) => {
        this.enumAssunto.push(...res);
      });
      EnumeradorService.buscar(
        'EnumSubCategoriaDuvidaSolicitacaoParticipanteOrcamento'
      ).then((res) => {
        this.enumAssunto.push(...res);
      });
      EnumeradorService.buscar(
        'EnumSubCategoriaDuvidaSolicitacaoParticipanteContrato'
      ).then((res) => {
        this.enumAssunto.push(...res);
      });
    },
    formatarStatus: function (item) {
      if (item) return this.enumStatus.find((el) => el.value === item).text;
    },
    alterarStatus: function (item, status) {
      let formStatus = {
        id: item.id,
        status: status,
        dataConclusao: new Date().toISOString(),
      };
      DuvidaSolicitacaoParticipanteService.alterarStatus(formStatus)
        .then(() => {
          this.listarRegistros();
          this.toastSucesso(
            this.$t(`modulos.duvida_solicitacao_participante.status_sucesso`)
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
