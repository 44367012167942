<template>
  <div class="row">
    <input-date
      v-model="filtro.dataAbertura"
      debugue
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.filtro.data_abertura')"
      em-filtro
    />
    <input-date
      v-model="filtro.dataConclusao"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.filtro.data_conclusao')"
      em-filtro
    />
    <input-select
      v-model="filtro.categoria"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.filtro.categoria')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.categoria"
      :loading="loading.categoria"
      em-filtro
      retornar-objeto
    />
    <input-text
      v-model="filtro.subCategoria"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.filtro.sub_categoria')"
      em-filtro
    />
    <input-text
      v-model="filtro.comentario"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.filtro.comentario')"
      em-filtro
    />
    <input-select
      v-model="filtro.status"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.filtro.status')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.status"
      :loading="loading.status"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      categoria: '',
      status:'',
      loading: {
        categoria: true,
        status: true,
      },
      opcoes: {
        categoria: [],
        status: [],
      },
    };
  },
  // watch:{
  //   'dataAbertura'(v){
  //     this.filtro.dataAbertura = v.substring(0,10)
  //   },
  //     'dataConclusao'(v){
  //     this.filtro.dataConclusao = v.substring(0,10)
  //   }
  // },
   mounted() {
    this.buscarEnumCategoriaParticipante();
    this.buscarEnumStatus();
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscarEnumCategoriaParticipante: function(){
      this.loading.categoria = true;
      EnumeradorService.buscar('EnumCatetegoriaDuvidaSolicitacaoParticipante')
      .then((res) => {
        this.opcoes.categoria = res
      })
      .finally(() => {
        this.loading.categoria = false;
      });
    },
    buscarEnumStatus: function () {
      this.loading.status = true;
      EnumeradorService.buscar('EnumStatusDuvidaSolicitacaoParticipante')
      .then((res) => {
        this.opcoes.status= res;
      })
      .finally(() => {
        this.loading.status = false;
      });
    },

  },
};
</script>

